import { Component, CUSTOM_ELEMENTS_SCHEMA, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from "../../../shared/shared.module";
import { FeatherModule } from 'angular-feather';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
//import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-index-it-solution',
  templateUrl: './index-it-solution.component.html',
  styleUrls: ['./index-it-solution.component.css'],
  standalone: true,
  imports: [SharedModule, FeatherModule, ScrollToModule,],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})

/***
 * It-Solution Component
 */
export class IndexItSolutionComponent implements OnInit {
  
  /* option peges */
  Menuoption = 'job';
  Settingicon = false;
  navClass = 'nav-light';


    
  

  testimonialData = [
    {
      profile: "assets/images/client/01.jpg",
      name: "Roberto",
      designation: "C.E.O",
      message: `" Implementar el chatbot AI de Solunes Digital ha transformado nuestro negocio. Ahora, respondemos consultas sobre productos en segundos, lo que ha aumentado nuestras ventas online. "`
    },
    {
      profile: "assets/images/client/02.jpg",
      name: "Roberto",
      designation: "C.E.O",
      message: `" Implementar el chatbot AI de Solunes Digital ha transformado nuestro negocio. Ahora, respondemos consultas sobre productos en segundos, lo que ha aumentado nuestras ventas online. "`
    },
    {
      profile: "assets/images/client/03.jpg",
      name: "Roberto",
      designation: "C.E.O",
      message: `" Implementar el chatbot AI de Solunes Digital ha transformado nuestro negocio. Ahora, respondemos consultas sobre productos en segundos, lo que ha aumentado nuestras ventas online. "`
    },
    {
      profile: "assets/images/client/04.jpg",
      name: "Roberto",
      designation: "C.E.O",
      message: `" Implementar el chatbot AI de Solunes Digital ha transformado nuestro negocio. Ahora, respondemos consultas sobre productos en segundos, lo que ha aumentado nuestras ventas online. "`
    },
    {
      profile: "assets/images/client/05.jpg",
      name: "C.E.O",
      designation: "Developer",
      message: `" Implementar el chatbot AI de Solunes Digital ha transformado nuestro negocio. Ahora, respondemos consultas sobre productos en segundos, lo que ha aumentado nuestras ventas online. "`
    },
    {
      profile: "assets/images/client/06.jpg",
      name: "C.E.O",
      designation: "Designer",
      message: `" Implementar el chatbot AI de Solunes Digital ha transformado nuestro negocio. Ahora, respondemos consultas sobre productos en segundos, lo que ha aumentado nuestras ventas online. "`
    }
  ];

  //host = window.location.hostname;

  constructor(private modalService: NgbModal, private gtmService: GoogleTagManagerService) { 
    //console.log(this.host);
  }

  ngOnInit(): void {
    this.sendPageLoadEvent();
  }

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 2,
    decimalPlaces: 0,
  };

  /**
  * Open modal for show the video
  * @param content content of modal
  */
  openWindowCustomClass(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', size: 'lg', centered: true });
  }

  trackEvent() {
    const dataLayer = {
      event: 'Boton contactar Chatbot',
      category: 'Button',
      action: 'Click',
      label: 'Track Button Click'
    };
    this.gtmService.pushTag(dataLayer); 
  }

  sendPageLoadEvent() {
    const dataLayer = {
      event: 'page_load', 
      page: window.location.pathname  
    };
    this.gtmService.pushTag(dataLayer);
  }
}
