import { Component, OnInit } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FeatherModule } from 'angular-feather';
import { CountUpModule } from 'ngx-countup';
import { SharedModule } from 'src/app/shared/shared.module';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import * as AOS from 'aos';

@Component({
  selector: 'app-portfolio-classic-two',
  templateUrl: './portfolio-classic-two.component.html',
  styleUrls: ['./portfolio-classic-two.component.css'],
  standalone:true,
  imports:[
    CountUpModule,SharedModule, FeatherModule, ScrollToModule
  ] 
})

/***
 * Portfolio classic two component
 */
export class PortfolioClassicTwoComponent implements OnInit {
  // Set Topbar
   /* option peges */
  navClass = 'nav-dark';
  Menuoption = 'job';
  Settingicon = false;

  num: number = 0;
  option = {
    startVal: this.num,
    useEasing: true,
    duration: 3,
    decimalPlaces: 0,
  };

  /**
   * Portfolio Classic Two Data
   */
  filterredImages = [{
    image: 'assets/images/solunes/samsung-bnn.png',
    title: 'Samsung - Bolivia',
    type: 'Branding',
    category: 'branding',
    url: 'https://www.samsung.com.bo'
  },
  {
    image: 'assets/images/solunes/bisa-bnn.png',
    title: 'Banco - BISA',
    type: 'Mockup',
    category: 'designing',
    url: 'https://portalqa.grupobisa.com/home'
  },
  {
    image: 'assets/images/solunes/bolivar-bnn.png',
    title: 'Club Bolivar',
    type: 'Abstract',
    category: 'photography',
    url: 'https://socio.clubbolivar.com/home',
  },
  {
    image: 'assets/images/solunes/libelula-bnn.png',
    title: 'Libélula',
    type: 'Books',
    category: 'development',
    url: 'https://libelula.bo/',
  },
  {
    image: 'assets/images/solunes/honor-bnn.png',
    title: 'Honor - Bolivia',
    type: 'Photography',
    category: 'branding',
    url: 'https://honorbolivia.com/'
  },
  {
    image: 'assets/images/solunes/tcl-bnn.webp',
    title: 'TCL - Bolivia',
    type: 'Cups',
    category: 'designing',
    url: 'https://tcl.solunes.site/home'
  },
  {
    image: 'assets/images/solunes/skyworks-bnn.webp',
    title: 'Skyworth - Bolivia',
    type: 'Article',
    category: 'development',
    url: 'https://skyworth.bo/suertuda/'
  },

  ];

  constructor(private gtmService: GoogleTagManagerService) { }

  ngOnInit(): void {
    AOS.init();
    this.sendPageLoadEvent();
    //this.filterredImages = this.list;
  }

  sendPageLoadEvent() {
    const dataLayer = {
      event: 'page_load', 
      page: window.location.pathname  
    };
    this.gtmService.pushTag(dataLayer);
  }

  /***
   * Active all category selected
   */
/*   activeCategory(category) {
    this.galleryFilter = category;
    if (this.galleryFilter === 'all') {
      this.filterredImages = this.list;
    } else {
      this.filterredImages = this.list.filter(x => x.category === this.galleryFilter);
    }
  } */

}
