<!-- Hero Start -->
<section class="bg-half-170 d-table w-100" style="background: url('assets/images/modern01.jpg') center center;"
  id="home">
  <div class="bg-overlay-custom2"></div>
  <div class="container">
    <div class="row align-items-center mt-md-5">

      <!-- <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0 order-2 order-md-1">
        <div class="card shadow rounded border-0 me-lg-3">
          <div class="card-body">
            <h5 class="card-title">Contáctanos</h5>

            <form class="login-form">
              <div class="row">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Nombre<span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="user" class="fea icon-sm icons"></i-feather>
                      <input type="text" class="form-control ps-5" placeholder="Nombre completo..." name="s" required="">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Correo electrónico<span class="text-danger">*</span></label>
                    <div class="form-icon position-relative">
                      <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                      <input type="email" class="form-control ps-5" placeholder="Correo electrónico" name="email" required="">
                    </div>
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="form-label">Mensaje</label>
                    <div class="form-icon position-relative">
                      <i-feather name="message-circle" class="fea icon-sm icons"></i-feather>
                      <textarea name="comments" id="comments" rows="4" class="form-control ps-5"
                        placeholder="Escribir..."></textarea>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="d-grid">
                    <button class="btn btn-success">Enviar</button>
                  </div>
                </div>

                <div class="col-lg-12 mt-4 text-center">
                  <h6>O en nuestras redes</h6>
                  <div class="row">
                    <div class="col-lg-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="uil uil-facebook text-primary"></i>
                          Facebook</a>
                      </div>
                    </div>
                    

                    <div class="col-lg-6 mt-3">
                      <div class="d-grid">
                        <a href="javascript:void(0)" class="btn btn-light"><i class="uil uil-google text-danger"></i>
                          Google</a>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div> -->
      

      <div class="col-lg-12 col-md-12 order-1 order-md-2">
        <div class="title-heading mt-4 ms-lg-4">
          <h1 class="heading title-dark text-white mb-3 text-center ">Diseñamos soluciones personalizadas <br> que transforman tu empresa.</h1>
          <p class="para-desc para-dark text-white-50 text-center m-auto">Impulsa tu campaña y aprovecha nuestra experiencia en diseño y gestión estratégica.</p>

          <div class="mt-4 pt-2 d-flex justify-content-center ">
            <a href="https://wa.link/dk5urn" target="_blank" class="btn btn-primary m-1" (click)="trackEvent()">Contactar <i class="uil uil-angle-right-b"></i></a>

           <!--  <a (click)="openWindowCustomClass(content)" href="javascript:void(0);" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><small class="fw-bold text-uppercase text-white-50 align-middle ms-1">Watch Now</small>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/99025203" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template> -->
          </div>

        </div>
      </div>
    </div>
  </div>
</section>


<div class="position-relative">
  <div class="shape overflow-hidden text-color-white">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div>


<!-- Partners start -->
<section class="py-4 border-bottom">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center py-4"></app-clients-logo>
  </div>
</section>

<section class="section" >
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Creamos Experiencias Digitales</h4>
          <p class="text-muted para-desc mb-0 mx-auto">En <span class="text-primary fw-bold">Solunes Digital </span> contamos con más de 10 años de experiencia. Un equipo multidisciplinario nos respalda, con procesos diseñados para crear productos que trascienden y escalan.</p>
        </div>
      </div>
    </div>

    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center" data-aos="zoom-out" data-aos-duration="2500" data-aos-once="true"></app-services>
  </div>
  

  <!-- <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-5">
        <div class="position-relative">
          <img src="assets/images/company/about.jpg" class="rounded img-fluid mx-auto d-block" alt="">
          <div class="play-icon">
            <a (click)="openModal(contentmodal)" href="javascript:void(0);" class="play-btn video-play-icon border-0">
              <i class="mdi mdi-play text-primary rounded-circle shadow"></i>
            </a>
            <ng-template #contentmodal let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                  allowfullscreen allow="autoplay; encrypted-media"></iframe>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="col-lg-7 col-md-7 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="section-title ms-lg-4">
          <h4 class="title mb-4">Startup Business Solution</h4>
          <p class="text-muted">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide
            everything you need to generate awareness, drive traffic, connect.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
          <a href="javascript:void(0)" class="btn btn-primary mt-3">Buy Now <i class="uil uil-angle-right-b"></i></a>
        </div>
      </div>
    </div>
  </div> -->
  <!-- <div class="container mt-100 mt-60">
    <app-features></app-features>
  </div> -->
</section>



<section class="section pb-4" >
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6" data-aos="fade-right" data-aos-duration="2500" data-aos-once="true">
        <div class="row align-items-center">
          <div class="col-lg-6 col-6 mt-4 mt-lg-0 pt-2 pt-lg-0">
            <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
              <div class="card-body p-0">
                <img src="assets/images/solunes/home3.png" class="img-fluid" alt="work-image">
                <div class="overlay-work bg-dark"></div>
                <div class="content">
                  <a href="javascript:void(0)" class="title text-white d-block fw-bold">Microservicios </a>
                  <!-- <small class="text-light">Para tu empresa</small> -->
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-6 col-6">
            <div class="row">
              <div class="col-lg-12 col-md-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/solunes/home2.png" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Ecosistema </a>
                      <!-- <small class="text-light">Ecosistema</small> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-lg-12 col-md-12 mt-4 pt-2">
                <div class="card work-container work-modern overflow-hidden rounded border-0 shadow-md">
                  <div class="card-body p-0">
                    <img src="assets/images/solunes/home1.png" class="img-fluid" alt="work-image">
                    <div class="overlay-work bg-dark"></div>
                    <div class="content">
                      <a href="javascript:void(0)" class="title text-white d-block fw-bold">Diseño de Producto</a>
                      <!-- <small class="text-light">C.A.</small> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7 col-md-6 mt-4 mt-lg-0 pt- pt-lg-0">
        <div class="ms-lg-4">
          <div class="section-title">
            <span class="badge bg-soft-primary rounded-pill fw-bold font-big">¿Por qué elegirnos?</span>
            <h4 class="title mb-4 mt-3">Tecnología propia basada en microservicios</h4>
            <p class="text-muted para-desc">Descubrimos que los microservicios son la clave para construir servicios modulares y escalables, permitiéndonos desarrollar plataformas y soluciones fácilmente integrables.</p>
          </div>

          <div class="mt-4 pt-2">
            <a href="https://wa.link/dk5urn" target="_blank" class="btn btn-primary m-1" (click)="trackEvent()" >Contactar <i
                class="uil uil-angle-right-b align-middle"></i></a>
          </div>
        </div>
      </div>
    </div>

  </div>

</section>



<section class="section pt-4" >
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Nuestros resultados trazaron el rumbo</h4>
          <p class="text-muted para-desc mx-auto mb-0">Con cada proyecto y cliente, hemos logrado generar unun <span class="text-primary fw-bold">impacto real</span> en sus objetivos.</p>
        </div>
      </div>
      
    </div>
    
  
    <div class="row" id="counter" data-aos="fade-up"
     data-aos-anchor-placement="top-bottom" data-aos-duration="2500" data-aos-once="true">
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/solunes/1icon-min.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="2" [options]="option"></span>M
          </h2>
          <h6 class="counter-head text-muted">Usuarios</h6>
        </div>
        
      </div>
  
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/solunes/2icon-min.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="100" [options]="option"></span>M$</h2>
          <h6 class="counter-head text-muted">Movimiento</h6>
        </div>
        
      </div>
  
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/solunes/3icon-min.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="2000" [options]="option"></span>%
          </h2>
          <h6 class="counter-head text-muted">crecimiento generado</h6>
        </div>
        
      </div>
  
      <div class="col-md-3 col-6 mt-4 pt-2">
        <div class="counter-box text-center">
          <img src="assets/images/solunes/4icon-min.svg" class="avatar avatar-small" alt="">
          <h2 class="mb-0 mt-4"><span class="counter-value" [countUp]="120" [options]="option"></span></h2>
          <h6 class="counter-head text-muted">proyectos</h6>
        </div>
      </div>
    </div>
    
  </div>

</section>







<!-- About End -->

<!-- CTA Start -->

<!-- <section class="section bg-cta" style="background: url('assets/images/2.jpg') center center;" id="cta">
  <div class="bg-overlay"></div>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title title-dark text-white mb-4">We Are Creative Dreamers and Innovators</h4>
          <p class="text-white-50 para-dark para-desc mx-auto">Start working with Landrick that can provide everything you
            need to generate awareness, drive traffic, connect.</p>
          <a (click)="opencreative(contentCreactive)" href="javascript:void(0);"
            class="play-btn mt-4 video-play-icon">
            <i-feather name="play" class="fea icon-ex-md text-white title-dark"></i-feather>
          </a>
          <ng-template #contentCreactive let-modal>
            <div class="modal-header">
              <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <iframe src="https://player.vimeo.com/video/287684225" height="450" width="780" frameborder="0"
                allowfullscreen allow="autoplay; encrypted-media"></iframe>
            </div>
          </ng-template>
        </div>
      </div>
      
    </div>
    
  </div>
  
</section> -->


<!-- Price Start -->
<!-- <section class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Charge your creative inspiration</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
      
    </div>
    
    <app-pricing [pricingData]="pricingData"></app-pricing>
  </div>
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 col-md-6">
        <img src="assets/images/illustrator/Startup_SVG.svg" class="img-fluid" alt="">
      </div>
      

      <div class="col-lg-7 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title ms-lg-5">
          <h4 class="title mb-4">A better compose with landrick marketing</h4>
          <p class="text-muted">You can combine all the Landrick templates into a single one, you can take a component
            from the Application theme and use it in the Website.</p>
          <ul class="list-unstyled mb-0 text-muted">
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Our Talented & Experienced Marketing Agency</li>
            <li class="mb-0"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
          </ul>
        </div>
      </div>
    </div>  
  </div>
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Important Clients Review</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-lg-9 mt-4 pt-2 text-center">
        <app-review-testmonial [reviewData]="reviewData" [firstimage]="1"></app-review-testmonial>
      </div>
    </div>
  </div>
</section> -->



<!-- <div class="position-relative">
  <div class="shape overflow-hidden text-light">
    <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
    </svg>
  </div>
</div> -->



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->