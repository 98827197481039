<!-- Hero Start -->
<section class="bg-half-170 pb-0 degHome d-table w-100" id="home">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading mb-md-5 pb-md-5">
          <!-- <h4 class="text-white-50">We are creative</h4> -->
          <h4 class="heading text-white mb-3 title-dark"> Automatiza tu soporte <br> con Chatbot con IA </h4>
          <p class="para-desc text-white-50">Genera un chatbot a medida que permita realizar conversaciones naturales con tus clientes adaptado a las necesidades de tu empresa.</p>
          <div class="mt-4 pt-2 d-flex flex-wrap align-conten-center gap-4 ">
            <a href="https://wa.link/j6uipn" target="_blank" class="btn btn-light" (click)="trackEvent()">Probar demo</a>
            <a href="https://wa.link/cinrda" target="_blank" class="btn btn-light" (click)="trackEvent()">Contactar</a>
          </div>
        </div>
      </div>
      <div class="col-lg-5 col-md-6 mt-5 mt-sm-0">        
        <img src="assets/images/solunes/imgHome.png" class="img-fluid" alt="img">
      </div>
    </div>
  </div>
</section>

<section class="section">
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">¿Por qué elegirnos?</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Prueba los beneficios de <span
              class="text-primary fw-bold">Carl</span>, nuestro chatbot que se adapta a tu negocio bajo los límites que tu establezcas.</p>
        </div>
      </div>
      
    </div>


    <div class="row">

      <article class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <!-- <span class="h1 icon2 text-primary">
            <i class="uil uil-chart-line"></i>
          </span> -->

          <lottie-player src="assets/json/24-hours_15370729.json" background="transparent" speed="1" loop autoplay class="icon-lotti"></lottie-player>

          <div class="card-body p-0 content">
            <h5>Interacción 24/7</h5>
            <p class="para text-muted mb-0">Responde a tus clientes en cualquier momento.</p>
          </div>
        </div>
      </article>

      <article class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
            <lottie-player src="assets/json/puzzle_15179530.json" background="transparent" speed="1" loop autoplay class="icon-lotti"></lottie-player>
          <div class="card-body p-0 content">
            <h5>Integración Sencilla</h5>
            <p class="para text-muted mb-0">Podemos conectar tus sistemas internos con el chatbot.</p>
          </div>
        </div>
      </article>

      <article class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <lottie-player src="assets/json/vector_8800707.json" background="transparent" speed="1" loop autoplay class="icon-lotti"></lottie-player>
          <div class="card-body p-0 content">
            <h5>Personalización</h5>
            <p class="para text-muted mb-0">Utilizamos nuestra experiencia según tus necesidades, para darte el chatbot listo para usar.</p>
          </div>
        </div>
      </article>

      <article class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <lottie-player src="assets/json/analysis_16678185.json" background="transparent" speed="1" loop autoplay class="icon-lotti"></lottie-player>
          <div class="card-body p-0 content">
            <h5>Análisis de Datos</h5>
            <p class="para text-muted mb-0">Obtén reportes y mejora continua.</p>
          </div>
        </div>
      </article>


      <!-- <div class="col-lg-12 text-center col-md-4 mt-4 pt-2">
        <a href="javascript:void(0)" class="btn btn-primary">See more
        </a>
      </div> -->

    </div>
  </div>
</section>



<section class="section ">

  <article class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Reduce costos y tiempo en soporte</h4>
          <p class="text-muted">Con nuestra solución potenciada sobre AI, tu negocio no solo está disponible 24/7, sino que además cambia el concepto del chatbot tradicional con opciones limitadas. Nuestro producto intenta generar una interacción bastante cercana al cliente final.</p>

          <div class="mt-4 pt-2 d-flex flex-wrap align-conten-center gap-4 ">
            <a href="https://wa.link/j6uipn" target="_blank"  class="btn btn-primary" (click)="trackEvent()">Probar demo <i class="uil uil-angle-right-b"></i></a>
            <a href="https://wa.link/cinrda" target="_blank" class="btn btn-light" (click)="trackEvent()">Contactar <i class="uil uil-angle-right-b"></i></a>
          </div>


        </div>
      </div>

      <div class="col-lg-6 col-md-6 order-1 order-md-2">
        <div class="content-animation">
            <lottie-player src="assets/json/computer_14183436.json" background="transparent" speed="1" loop autoplay class="icon-lotti lt1"></lottie-player>
            <lottie-player src="assets/json/forum_15374789.json" background="transparent" speed="1" loop autoplay class="icon-lotti lt2"></lottie-player>
            <lottie-player src="assets/json/laptop_17122711.json" background="transparent" speed="1" loop autoplay class="icon-lotti lt3"></lottie-player>
            <lottie-player src="assets/json/phone-call_17569493.json" background="transparent" speed="1" loop autoplay class="icon-lotti lt4"></lottie-player>
            <img src="assets/images/solunes/section5.png" class="img-fluid" alt="img">
          </div>
      </div>
    </div>
  </article>

</section>

<section class="degHome w-100 py-60">

  <div class="container">

    <div class="row justify-content-center mb-4">
      <div class="col-12 text-center">
        <div class="section-title mb-4">
          <h4 class="title text-white">Aprovecha los beneficios de la IA en tu empresa</h4>
        </div>  
      </div>

      <div class="col-12">
        <div class="content-img">
          <img src="assets/images/solunes/img-1.png" alt="img">
          <img src="assets/images/solunes/img-2.png" alt="img">
          <img src="assets/images/solunes/img-3.png" alt="img">
        </div>
      </div>

    </div>

    <div class="row">

      <div class="col-12 text-center">
        <div class="section-title ">
          <h4 class="title text-white">Casos de uso específico</h4>
        </div>
      </div>

      <article class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow h-100">
          
          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Retail</a>
            <p class="text-muted mt-2">Impulsa tus ventas con
            recomendaciones personalizadas en tiempo real. Realizamos ventas sobre preguntas, no sobre opciones.</p>
            <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i class="uil uil-angle-right-b"></i></a>
            <i class="uil uil-layer-group text-primary full-img"></i> -->
          </div>
        </div>
      </article>
    
      <article class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow h-100">
          
          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Inmobiliarias</a>
            <p class="text-muted mt-2">Genera leads y búsquedas a medida en una conversación. Recomendamos propiedades según las solicitudes del cliente.</p>
            <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i class="uil uil-angle-right-b"></i></a>
            <i class="uil uil-airplay text-primary full-img"></i> -->
          </div>
        </div>
      </article>
    
      <article class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow h-100">
          
          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Restaurantes</a>
            <p class="text-muted mt-2">Integra tu servicio de autoservicio para realizar pedidos o dar información. Recomendamos productos a medida en una conversación.</p>
            <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i class="uil uil-angle-right-b"></i></a>
            <i class="uil uil-focus-target text-primary full-img"></i> -->
          </div>
        </div>
      </article>
    
      <article class="col-lg-3 col-md-6 mt-4 pt-2">
        <div class="card border-0 features feature-primary feature-clean course-feature p-4 overflow-hidden shadow h-100">
          
          <div class="card-body p-0 mt-4">
            <a href="javascript:void(0)" class="title h5 text-dark">Servicios</a>
            <p class="text-muted mt-2">Responde las preguntas de tus clientes que vengan de una amplia base de conocimiento. Aprovecha de sugerir el pago de mensualidades.</p>
            <!-- <a href="javascript:void(0)" class="text-primary read-more">Read More <i class="uil uil-angle-right-b"></i></a>
            <i class="uil uil-expand-arrows text-primary full-img"></i> -->
          </div>
        </div>
      </article>
    </div>

  </div>
</section>


<section class="section ">

  <article class="container">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-6 order-1 order-md-1">
        <img src="assets/images/solunes/section3.png" class="img-fluid" alt="img">
      </div>

      <div class="col-lg-6 col-md-6 order-2 order-md-2 mt-4 mt-sm-0 pt-2 pt-sm-0">
        <div class="section-title">
          <h4 class="title mb-4">Nos encargamos de la creación por ti</h4>

            <ul class="text-muted">
              <li><b>Personalización:</b> Dejanos a nosotros personalizar el producto a medida, con nuestra experiencia. </li>
              <li>
                <b>Tecnología flexible:</b> Contamos con herramientas de integración para conectar tu negocio con el cliente.
              </li>
              <li>
                <b>Modo humano:</b> Damos un acceso al operador de su empresa para atender o responder los casos que necesiten intervención humana.
              </li>
              <li><b>Mejora continua:</b> Utilizamos nuestras conversaciones previas para ir mejorando constantemente.</li>
            </ul>
            
          <div class="mt-4 pt-2 d-flex flex-wrap align-conten-center gap-4 ">
            <a href="https://wa.link/j6uipn" target="_blank" class="btn btn-primary" (click)="trackEvent()">Probar demo <i
                class="uil uil-angle-right-b"></i></a>
            <a href="https://wa.link/cinrda" target="_blank" class="btn btn-light" (click)="trackEvent()">Contactar <i
                class="uil uil-angle-right-b"></i></a>
          </div>

        </div>
      </div>

    </div>
  </article>

</section>


<!-- <section class="section bg-light py-60">
  <div class="container ">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Testimonios</h4>
          <p class="text-muted para-desc mx-auto mb-0">Lee lo que nuestros clientes dicen acerca del producto</p>
        </div>
      </div>
    </div>
    <app-testimonial [testimonialData]="testimonialData"></app-testimonial>
  </div>
</section> -->


<section class="container py-60">
  <div class="row justify-content-center">
    <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">¡Transforma tu negocio hoy mismo!</h4>
          <p class="text-muted para-desc mx-auto">Nuestro proceso de implementación puede hacerse en tan solo unos días. Tu no tienes que hacer nada.</p>
          <div class="subcribe-form mt-4 pt-2">
          </div>
        </div>
    </div>
  </div>
</section>




<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->