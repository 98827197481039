import { Component } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'landrick-angular';

  constructor(private router: Router, private gtmService: GoogleTagManagerService) {
    /**
     * Unicons icon refreshed on route change.
     */
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        // window['Unicons']();
      }

      if (!(event instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });
  }

  numberContact(){
    this.trackEvent();
    window.open('https://wa.link/dk5urn', '_blank');
  }


  trackEvent() {
    const dataLayer = {
      event: 'Boton Whatsapp Click',
      category: 'Button',
      action: 'Click',
      label: 'Track Button Click'
    };
    this.gtmService.pushTag(dataLayer); 
  }

}
