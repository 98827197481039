<!-- Hero Start -->
<section class="bg-half-170 d-table w-100 overflow-hidden" id="home">
  <div class="container">
    <div class="row align-items-center pt-5">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading">
          <h1 class="heading mb-3">Conecta, fideliza y haz crecer tu  comunidad</h1>
          <p class="para-desc text-muted"> <span class="text-primary fw-bold" >+FAN </span> es una plataforma de loyalty que permite generar funciones que automaticen la generación de puntos, misiones, metas en un formato de gamificación.</p>
          <div class="mt-4 pt-2">
            <a href="https://wa.link/dk5urn" target="_blank" class="btn btn-primary" (click)="trackEvent()">Solicitar un demo</a>
          </div>
        </div>
      </div>

      <div class="col-lg-5 col-md-6 mt-4 pt-2 mt-sm-0 pt-sm-0">
        <div class="classic-saas-image position-relative">
          <div class="bg-saas-shape position-relative">
            <img src="assets/images/solunes/lapto-admin.png" class="mx-auto d-block" alt="">
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- <section class="pt-5">
  <div class="container">
    <app-clients-logo brand_class="col-lg-2 col-md-2 col-6 text-center"></app-clients-logo>
  </div>
</section> -->

<!-- <section class="section overflow-hidden">
  <div class="container">
    <div class="row">

      <article class="col-md-3 col-12 mb-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>Membresías</h5>
            <p class="text-muted mb-0">El módulo de membresías es una solución diseñada para gestionar la inscripción, suscripción y administración de usuarios en diversos servicios o productos basados en membresía.</p>
          </div>
        </div>
      </article>
  
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>Tickets</h5>
            <p class="text-muted mb-0">El módulo de Tickets y Eventos es una solución diseñada para facilitar la organización, promoción y gestión de eventos de cualquier escala.</p>
          </div>
        </div>
      </article>
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>CRM</h5>
            <p class="text-muted mb-0">El servicio de CRM y es una solución diseñada para centralizar y optimizar la gestión de relaciones con clientes.</p>
          </div>
        </div>
      </article>
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>E-commerce</h5>
            <p class="text-muted mb-0">El módulo de E-commerce está diseñado para las operaciones de venta de productos, proporcionando una plataforma robusta para la gestión de productos e inventarios y personalización de pedidos.</p>
          </div>
        </div>
      </article>
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>Website</h5>
            <p class="text-muted mb-0">Plataforma centralizada para comunicar y conectar con los hinchas, fortaleciendo la identidad del club y manteniendo a los aficionados informados.</p>
          </div>
        </div>
      </article>
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>Multimedia</h5>
            <p class="text-muted mb-0">Desde noticias y actualizaciones en tiempo real hasta comunicaciones personalizadas, aseguramos que los aficionados reciban la información más relevante de manera oportuna.</p>
          </div>
        </div>
      </article>
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>App iOS / Android</h5>
            <p class="text-muted mb-0">Ofrece una experiencia personalizada y de fácil acceso, permitiendo a los fans acceder a contenido exclusivo y recibir notificaciones en tiempo real, mejorando el engagement y la lealtad.</p>
          </div>
        </div>
      </article>
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>Fan-engagement</h5>
            <p class="text-muted mb-0">Gestión completa de actividades: Administración de encuestas, concursos, sorteos y programas de puntos. Análisis de datos de participación.</p>
          </div>
        </div>
      </article>
      <article class="col-md-3 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>Admin/ Reportes</h5>
            <p class="text-muted mb-0">Optimiza la gestión del club, mediante el registro de socios, gestión de eventos, análisis estadísticos, generación de reportes y sobre todo ver el movimiento del club en tiempo real.</p>
          </div>
        </div>
      </article>
      <article class="col-md-4 col-12 p-4">
        <div class="feature-primary features text-center">
          <div class="image position-relative d-inline-block">
            <i class="uil uil-calendar-alt h2 text-primary"></i>
          </div>
    
          <div class="content mt-4">
            <h5>Control de acceso</h5>
            <p class="text-muted mb-0">Gestión completa de acceso a espacios deportivos, administración y configuración de sectores, butacas, áreas comunes. Accesos mediante tarjetas RFC y códigos QR.</p>
          </div>
        </div>
      </article>
    </div>
    
  </div>
</section> -->

<section class="section overflow-hidden pt-0">
  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">El Ecosistema de Clubes Deportivos</h4>
          <p class="text-muted para-desc mb-0 mx-auto"><span class="text-primary fw-bold">+FAN</span> es la solución ideal para clubes deportivos, ya que se conecta a las herramientas comerciales de los clubes y les genera un fan engagement, necesario para darle uso a la plataforma de lealtad.</p>
        </div>
        <img src="assets/images/solunes/mapa-fan.png" class="img-fluid mx-auto d-block w-100" alt="img">
      </div>
    </div>
  </div>
</section>

<section class="section overflow-hidden">
  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-6 col-md-5">
        <div class="app-feature-shape-left position-relative">
          <div class="text-center text-md-start">
            <img src="assets/images/solunes/phone-fan.png" class="img-fluid" alt="">
          </div>
        </div>
      </div>
  
      <div class="col-lg-6 col-md-7 mt-5 mt-sm-0">
        <div class="section-title">
          <h1 class="title mb-3">¿Por qué elegir +FAN?</h1>
          <p class="para-desc text-muted">+FAN centraliza la gestión de los clubes, aumentando la comunidad de hinchas y maximizando ingresos.</p>
          <ul class="list-unstyled text-muted">
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Aumenta y fideliza a los hinchas con soluciones integrales.</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Impulsa ingresos en asistencia, merchandising y abonos.</li>
            <li class="mb-1"><span class="text-primary h5 me-2"><i
                  class="uil uil-check-circle align-middle"></i></span>Administra todo desde una única plataforma.</li>
          </ul>
          <div class="mt-4">
            <a href="https://wa.link/dk5urn" target="_blank" class="mt-3 h6 text-primary" (click)="trackEvent()">Solicitar un demo <i
                class="uil uil-angle-right-b"></i></a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-start text-center">
            <h4 class="title mb-3 text-white title-dark">Conoce nuestras herramientas.</h4>
            <p class="text-white-50 mb-0">Contamos con todas las herramientas necesesarias para clubes deportivos.</p> 
          </div>
        </div>
  
        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a href="https://wa.link/dk5urn" target="_blank" class="btn btn-light" (click)="trackEvent()">Solicitar un demo</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<!-- <section class="section overflow-hidden">
  <div class="container pb-5 mb-md-5">
    <app-services [servicesData]="servicesData" service_class="features feature-primary text-center"></app-services>
  </div>

  <div class="container pb-5 mb-md-5 mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-7">
        <div class="saas-feature-shape-left position-relative">
          <img src="assets/images/saas/classic02.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>

      <div class="col-lg-5 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title ms-lg-4">
          <h1 class="title mb-3">Build your site for using this app</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>

          <owl-carousel-o id="customer-testi" [options]="customOptions">
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" It seems that only fragments of the original text remain in the Lorem Ipsum
                  texts used today. "</p>
                <h6 class="text-primary">- Thomas Israel</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/02.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" The most well-known dummy text is the 'Lorem Ipsum', which is said to have
                  originated in the 16th century. "</p>
                <h6 class="text-primary">- Carl Oliver</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/03.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" One disadvantage of Lorum Ipsum is that in Latin certain letters appear
                  more frequently than others. "</p>
                <h6 class="text-primary">- Barbara McIntosh</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/04.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" Thus, Lorem Ipsum has only limited suitability as a visual filler for
                  German texts. "</p>
                <h6 class="text-primary">- Jill Webb</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/05.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" There is now an abundance of readable dummy texts. These are usually used
                  when a text is required. "</p>
                <h6 class="text-primary">- Dean Tolle</h6>
              </div>
            </ng-template>
            <ng-template carouselSlide>
              <div class="customer-testi py-0">
                <img src="assets/images/client/06.jpg" class="img-fluid avatar avatar-small rounded-circle shadow"
                  alt="">
                <p class="text-muted mt-4">" According to most sources, Lorum Ipsum can be traced back to a text
                  composed by Cicero. "</p>
                <h6 class="text-primary">- Christa Smith</h6>
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-100 mt-60">
    <div class="row align-items-center">
      <div class="col-lg-5 order-2 order-lg-1 mt-4 pt-2 mt-lg-0 pt-lg-0">
        <div class="section-title me-lg-4">
          <h1 class="title mb-3">Why Choose us ?</h1>
          <p class="para-desc text-muted">Launch your campaign and benefit from our expertise on designing and managing
            conversion centered bootstrap v5 html page.</p>

          <div class="row">
            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i-feather name="shield" class="fea icon-m-md text-primary"></i-feather>
                </h2>
                <div class="ms-3">
                  <h5>Fully Secured</h5>
                  <p class="text-muted mb-0">Moreover, in Latin only words at the beginning of sentences are
                    capitalized.</p>
                </div>
              </div>
            </div>

            <div class="col-12">
              <div class="d-flex align-items-center pt-4">
                <h2>
                  <i-feather name="cpu" class="fea icon-m-md text-primary"></i-feather>
                </h2>
                <div class="ms-3">
                  <h5>Best Performance</h5>
                  <p class="text-muted mb-0">If the fill text is intended to illustrate the characteristics of
                    sometimes.</p>
                </div>
              </div>
            </div>

            <div class="col-12 pt-4">
              <a href="javascript:void(0)" class="btn btn-outline-primary">Install Now <i
                  class="uil uil-angle-right-b"></i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-7 order-1 order-lg-2">
        <div class="saas-feature-shape-right position-relative">
          <img src="assets/images/saas/classic02.png" class="img-fluid mx-auto d-block rounded shadow" alt="">
        </div>
      </div>
    </div>
  </div>

  <div class="container pt-4 mt-100 mt-60">
    <div class="row justify-content-center" id="counter">
      <div class="col-12 text-center">
        <div class="section-title">
          <h4 class="title mb-4">Overall <span class="text-primary">
              <span class="counter-value" [countUp]="333" [options]="option"></span>k+</span> client are using,
            Get Started</h4>
          <p class="text-muted para-desc mx-auto mb-0">Build responsive, mobile-first projects on the web with the
            world's most popular front-end component library.</p>
          <div class="watch-video mt-4">
            <a href="javascript:void(0)" class="btn btn-primary m-1 me-2">Get Started <i
                class="mdi mdi-chevron-right"></i></a>
            <a (click)="openWindowCustomClass(content)" href="javascript:void(0)" data-type="youtube"
              data-id="yba7hPeTSjk" class="btn btn-icon btn-pills btn-primary m-1">
              <i-feather name="video" class="icons"></i-feather>
            </a><span class="fw-bold text-uppercase small align-middle ms-1">Watch Now</span>
            <ng-template #content let-modal>
              <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <youtube-player [videoId]="'jNTZpfXYJa4'" height="450" width="780">
                </youtube-player>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container mt-100 mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our Pricing Rates</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
              class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness,
            drive traffic, connect.</p>
        </div>
      </div>
    </div>
    <app-pricing [pricingData]="pricingData"></app-pricing>
  </div>

  <div class="container mt-100 mt-60">
    <div class="rounded bg-primary bg-gradient p-lg-5 p-4">
      <div class="row align-items-end">
        <div class="col-md-8">
          <div class="section-title text-md-start text-center">
            <h4 class="title mb-3 text-white title-dark">Start your free 2 week trial today</h4>
            <p class="text-white-50 mb-0">Start working with Landrick that can provide everything you need to generate
              awareness, drive traffic, connect.</p>
          </div>
        </div>

        <div class="col-md-4 mt-4 mt-sm-0">
          <div class="text-md-end text-center">
            <a href="javascript:void(0)" class="btn btn-light">Get Started</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> -->



<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->