<!-- Hero Start -->
<ngx-slick-carousel [config]="config" class="carousel bg-half-home" id="home">
    <div class="swiper-slide" ngxSlickItem>
        <div class="slide-inner slide-bg-image d-flex align-items-center" style="background: center center;"
            style="background-image:url('assets/images/real/1.jpg')">
            <div class="bg-overlay-custom2"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="title-heading text-center my-5">

                            <h4 class="display-4 fw-bold text-white title-dark mb-3">Mejora tu gestión inmobiliaria </h4>

                            <p class="para-desc text-white-50 mb-0 mx-auto">Optimiza tus procesos con herramientas diseñadas para potenciar la administración de propiedades.</p>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
            <!--end container-->
        </div><!-- end slide-inner -->
    </div> <!-- end swiper-slide -->
    <div class="swiper-slide" ngxSlickItem>
        <div class="slide-inner slide-bg-image d-flex align-items-center" style="background: center center;"
            style="background-image:url('assets/images/real/2.jpg')">
            <div class="bg-overlay-custom2"></div>
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12">
                        <div class="title-heading text-center my-5">
                            <h4 class="display-4 fw-bold text-white title-dark mb-3">Gestión simple y eficiente</h4>
                            <p class="para-desc text-white-50 mb-0 mx-auto">Simplifica tu administración con soluciones que integran todo en un solo lugar.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</ngx-slick-carousel>




<section class="section pt-4">
    <!--end container-->

    <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <span class="badge rounded-pill bg-soft-primary">Personalización</span> 
                    <h4 class="title mt-3 mb-4">¿Por qué elegirnos?</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Trabaja con <span
                            class="text-primary fw-bold">Solunes Digital</span> y ten una plataforma personalizada e implementada para tu inmobiliaria, lista para utilizar.</p> 
                </div>
            </div>
        </div>


        <div class="row">

            <article class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 text-center features feature-primary feature-clean rounded">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-map-marker-plus d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Modernidad</a>
                        <p class="text-muted mt-3 mb-0">Ofrece una herramienta limpia para tu cliente final.</p>
                        <!-- <div class="mt-2">
                            <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                        </div> -->
                    </div>
                </div>
            </article>

            <article class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 text-center features feature-primary feature-clean rounded">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-estate d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Seguimiento y análisis</a>
                        <p class="text-muted mt-3 mb-0">Seguimiento y análisis de propiedades en tiempo real.</p>
                        <!-- <div class="mt-2">
                            <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                        </div> -->
                    </div>
                </div>
            </article>

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 text-center features feature-primary feature-clean rounded">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-house-user d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Sistema CRM</a>
                        <p class="text-muted mt-3 mb-0">Sistema CRM para gestión de ejecutivos de ventas.</p>
                        <!-- <div class="mt-2">
                            <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                        </div> -->
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-12 mt-4 pt-2">
                <div class="card border-0 text-center features feature-primary feature-clean rounded">
                    <div class="icons text-primary text-center mx-auto">
                        <i class="uil uil-house-user d-block rounded h3 mb-0"></i>
                    </div>

                    <div class="card-body p-0 mt-4">
                        <a href="javascript:void(0)" class="title h5 text-dark">Integración a sitios</a>
                        <p class="text-muted mt-3 mb-0">Integración a sitios inmobiliarios para publicación automática.</p>
                        <!-- <div class="mt-2">
                            <a href="javascript:void(0)" class="text-primary">Read More <i-feather name="arrow-right" class="fea icon-sm"></i-feather></a>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>


        <div class="container mt-100 mt-60">
            <div class="row align-items-center">
                <div class="col-lg-7 col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title me-lg-5">
                        <h4 class="title mb-4">Automatiza tu proceso</h4>
                        <p class="text-muted"> En un sector tan competitivo como el inmobiliario, el contacto con el cliente y la recepción y respuesta de leads consume mucho tiempo. Aprovecha nuestras herramientas complementarias para automatizar y o semiautomatizar tu soporte con el cliente final generando mejores resultados. </p>
                        <!-- <ul class="list-unstyled text-muted">
                            <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Digital Marketing Solutions for Tomorrow</li>
                            <li class="mb-1"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Create your own skin to match your brand</li>
                        </ul>
                        <a href="javascript:void(0)" class="mt-3 h6 text-primary">Find Out More <i class="uil uil-angle-right-b"></i></a> -->
                        <div class="mt-4 d-none d-lg-block">
                            <a href="https://wa.link/dk5urn" target="_blank" class="btn btn-primary" (click)="trackEvent()">Contactar con un agente</a>
                        </div>
                    </div>
                </div>
        
                <div class="col-lg-5 col-md-6 order-1 order-md-2">
                    <img src="assets/images/solunes/real2.webp" alt="img" class="w-100">
                </div>
            </div>
        </div>

    </div>


    <!-- <div class="container mt-100 mt-60">
        <div class="row align-items-center">
            <div class="col-lg-4">
                <div class="section-title text-center text-lg-start mb-4 mb-lg-0">
                    <span class="badge rounded-pill bg-soft-primary">Featured Items</span>
                    <h4 class="title mt-3 mb-4">Latest Property</h4>
                    <p class="text-muted mx-auto mx-lg-star para-desc mb-0">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                    <div class="mt-4 d-none d-lg-block">
                        <a href="javascript:void(0)" class="btn btn-primary">See More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-8 mt-4 mt-lg-0 pt-2 pt-lg-0">
                <owl-carousel-o id="customer-testi" [options]="customOptions">
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p1.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p2.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p3.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p4.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p5.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p6.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p7.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template carouselSlide>
                        <div class="tiny-slide">
                            <div
                                class="card client-testi shop-list border-0 shadow position-relative overflow-hidden m-2">
                                <div class="shop-image position-relative overflow-hidden shadow">
                                    <a routerLink="/shop-product-detail"><img src="assets/images/real/p8.jpg"
                                            class="img-fluid" alt=""></a>
                                    <ul class="list-unstyled shop-icons">
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-estate icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-heart icons"></i></a></li>
                                        <li class="list-inline-item"><a href="javascript:void(0)"
                                                class="btn btn-icon btn-pills btn-primary"><i
                                                    class="uil uil-camera icons"></i></a></li>
                                    </ul>
                                </div>
                                <div class="card-body content p-4">
                                    <a href="javascript:void(0)" class="text-dark product-name h6">Luxury Apartment In
                                        chelsea</a>
                                    <ul class="list-unstyled text-muted mt-2 mb-0">
                                        <li class="list-inline-item me-3"><i class="uil uil-bed me-1"></i>1 Bed</li>
                                        <li class="list-inline-item"><i class="uil uil-bath me-1"></i>1 Bath</li>
                                    </ul>
                                    <ul class="list-unstyled d-flex justify-content-between mt-2 mb-0">
                                        <li class="list-inline-item"><b>$ 44000</b></li>
                                        <li class="list-inline-item text-muted">$ 850 / sqft</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </owl-carousel-o>
            </div>

        </div>

    </div> -->



   <!--  <div class="container-fluid mt-100 mt-60">
        <div class="rounded shadow py-5" style="background: url('assets/images/real/1.jpg') center center;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="rounded p-4 bg-light">
                            <h4 class="card-title title text-dark">
                                La visibilidad en tiempo real y los reportes detallados han sido claves para optimizar nuestra rentabilidad y mejorar nuestra toma de decisiones. Sin duda, ha sido una inversión que ha potenciado nuestro negocio.
                            </h4>
                            <app-review-testmonial [reviewData]="reviewData" [firstimage]="1" ></app-review-testmonial>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> -->
    <!--end container fluid-->

    <!-- <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12">
                <div class="section-title mb-4 pb-2 text-center">
                    <h4 class="title mb-4">Our Experts</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Start working with <span
                            class="text-primary fw-bold">Landrick</span> that can provide everything you need to
                        generate awareness, drive traffic, connect.</p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team bg-light text-center rounded shadow border-0">
                    <div class="card-body">
                        <div class="routerLinkn-/relative">
                            <img src="assets/images/client/01.jpg"
                                class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                            <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                            </ul>
                        </div>
                        <div class="content pt-3">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Ronny Jofra</a></h5>
                            <small class="designation text-muted">Commercial Broker</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team bg-light text-center rounded shadow border-0">
                    <div class="card-body">
                        <div class="routerLinkn-/relative">
                            <img src="assets/images/client/04.jpg"
                                class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                            <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                            </ul>
                        </div>
                        <div class="content pt-3">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Micheal Carlo</a></h5>
                            <small class="designation text-muted">Commercial Broker</small>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team bg-light text-center rounded shadow border-0">
                    <div class="card-body">
                        <div class="routerLinkn-/relative">
                            <img src="assets/images/client/03.jpg"
                                class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                            <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                            </ul>
                        </div>
                        <div class="content pt-3">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Aliana Rosy</a></h5>
                            <small class="designation text-muted">Commercial Broker</small>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-lg-3 col-md-6 mt-4 pt-2">
                <div class="card team bg-light text-center rounded shadow border-0">
                    <div class="card-body">
                        <div class="routerLinkn-/relative">
                            <img src="assets/images/client/02.jpg"
                                class="img-fluid avatar avatar-ex-large rounded-circle shadow" alt="">
                            <ul class="list-unstyled social-icon team-icon mb-0 mt-4">
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="facebook" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="instagram" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="twitter" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                                <li class="list-inline-item ms-1"><a href="javascript:void(0)" class="rounded">
                                        <i-feather name="linkedin" class="fea icon-sm fea-social"></i-feather>
                                    </a></li>
                            </ul>
                        </div>
                        <div class="content pt-3">
                            <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Sofia Razaq</a></h5>
                            <small class="designation text-muted">Commercial Broker</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
     -->

</section>

<div class="position-relative">
    <div class="shape overflow-hidden text-light">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<section class="section bg-light">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Solución sólida</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Nuestra plataforma ofrece una solución segura y generada para utilizarse en un largo plazo. </p>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
            <div class="row">
                <div class="col-md-6 col-12 mt-4 pt-2">
                    <div class="d-flex">
                        <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                        <div class="flex-1">
                            <!-- <h5 class="mt-0">How our <span class="text-primary">Landrick</span> work ?</h5> -->
                            <h5 class="mt-0">Automatización de tareas</h5>
                            <p class="answer text-muted mb-0">Reduce tiempos de respuesta automatizando tu proceso con tu cliente.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12 mt-4 pt-2">
                    <div class="d-flex">
                        <i-feather name="help-circle" class="fea icon-ex-md text-primary me-2 mt-1"></i-feather>
                        <div class="flex-1">
                            <h5 class="mt-0"> Visibilidad de propiedades </h5>
                            <p class="answer text-muted mb-0">Consulta en tiempo real el estado y la rentabilidad de tu cartera desde un dashboard.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12 mt-4 pt-2">
                    <div class="d-flex">
                        <i-feather name="help-circle" class="fea icon-check text-primary me-2 mt-1"></i-feather>
                        <div class="flex-1">
                            <h5 class="mt-0">Gestión de clientes</h5>
                            <p class="answer text-muted mb-0">Usa herramientas CRM para gestionar y seguir a cada cliente desde el inicio hasta el cierre.</p>
                        </div>
                    </div>
                </div>
                <!--end col-->

                <div class="col-md-6 col-12 mt-4 pt-2">
                    <div class="d-flex">
                        <i class="uil uil-check-circle align-middle text-primary me-2 mt-1"></i>
                        <div class="flex-1">
                            <h5 class="mt-0">Reportes en Tiempo Real</h5>
                            <p class="answer text-muted mb-0">Crea reportes detallados para medir el rendimiento de propiedades y proyectos.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--end row-->

        <!-- <div class="row mt-5 pt-lg-3 justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title">
                    <h4 class="title mb-4">Download Our App Now !</h4>
                    <p class="text-muted para-desc mx-auto">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                    <div class="mt-4 pt-2">
                        <div class="text-center subcribe-form">
                            <form class="mb-4">
                                <input name="number" id="number" type="number" class="form-control rounded-pill"
                                    placeholder="Your Cell Number. :" required="" aria-describedby="cellnumber">
                                <button class="btn btn-pills btn-primary submitBnt" type="submit" id="cellnumber">Send App Link</button>
                            </form>
                        </div>
                        <p class="text-muted">Or</p>
                        <a href="javascript:void(0)" class="btn btn-primary mt-2 me-2"><i class="uil uil-apple"></i> App
                            Store</a>
                        <a href="javascript:void(0)" class="btn btn-outline-primary mt-2"><i class="uil uil-google-play"></i> Play Store</a>
                    </div>
                </div>
            </div>
        </div> -->

    </div>
</section>




<!-- News Start -->
<section class="section " style="background: url('assets/images/real/build.png') bottom no-repeat;">

    <div class="container section pt-0 ">
        <div class="row align-items-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">¿Nuestros diferenciadores?</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Contamos con una plataforma sólida, que personalizaremos ségún tus necesidades para darte un buen resultado.</p>
                </div>
            </div>
            <div class="col-lg-7 col-md-6 order-1 order-md-2 mt-4 mt-sm-0 pt-2 pt-sm-0">
                <div class="section-title me-lg-5">
                    <ul class="list-unstyled text-muted">
                        <li class="mb-1 d-flex"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Automatiza las tareas repetitivas y administrativas.</li>
                        <li class="mb-1 d-flex"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Si tienes una fuente de información externa, nos podemos integrar.</li>
                        <li class="mb-1 d-flex"><span class="text-primary h5 me-2"><i class="uil uil-check-circle align-middle"></i></span>Aprovecha la IA para potenciar tu negocio.</li>
                    </ul>
                </div>
            </div>
    
            <div class="col-lg-5 col-md-6 order-2 order-md-1">
                <img src="assets/images/solunes/lapto.png" alt="img" class="w-100">
            </div>
        </div>
    </div>

    <!-- <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title  pb-2">
                    <h4 class="title mb-4">Planes y precios</h4>
                    <p class="text-muted para-desc mb-0 mx-auto">Te ofrecemos los mejores precios del mercado</p>
                </div>
            </div>
        </div>
    
        <div class="row align-items-center">
            <div class="col-12 mt-4 ">
                <div class="text-center">
                    <app-pricing [pricingData]="yearlyData"></app-pricing>
                </div>
            </div>
        </div>
    </div> -->
    


    <!-- <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 text-center">
                <div class="section-title mb-4 pb-2">
                    <h4 class="title mb-4">Latest Blog & News</h4>
                    <p class="text-muted para-desc mx-auto mb-0">Start working with <span class="text-primary fw-bold">Landrick</span> that can provide everything you need to generate awareness, drive traffic, connect.</p>
                </div>
            </div>
        </div>
        <app-blog [blogData]="blogData"></app-blog>
    </div> -->
</section>



<!--end section-->
<!-- Blog End -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->