import { Component } from '@angular/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FeatherModule } from 'angular-feather';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Component({
  selector: 'app-corporate-services',
  templateUrl: './corporate-services.component.html',
  styleUrls: ['./corporate-services.component.css'],
  standalone:true,
  imports:[
    FeatherModule, ScrollToModule
  ]
})
export class CorporateServicesComponent {
// Set Topbar
   /* option peges */
  navClass = 'nav-light';
  Menuoption = 'job';
  Settingicon = false

  constructor(
    private gtmService: GoogleTagManagerService
  ) { }


   ngOnInit(): void {
    this.sendPageLoadEvent();
  }

  sendPageLoadEvent() {
    const dataLayer = {
      event: 'page_load', 
      page: window.location.pathname  
    };
    this.gtmService.pushTag(dataLayer);
  }


}
